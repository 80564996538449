import React, { ReactComponentElement, ReactElement, ReactNode, useMemo, useState } from 'react';
import { Movie } from '../gql/graphql-types';
import styled from '@emotion/styled';
import * as colors from '@ant-design/colors';
import { selectValue } from '../utils/ScoreUtils';
import { mean } from 'ramda';
import { pathOr } from 'ramda';
import { Box, Paper, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';


const StyledTable = styled.table`
    margin: 2em 1em;
    width: calc(100% - 2em);

    th {
        border-bottom: 1px ${colors.blue.primary} solid;
        text-align: left;
        padding: 0.25em;
    }

    td {
        padding: 0.25em;
    }

    tbody tr:hover {
        background-color: #e5e1e1;
    }
`

export const selectMovieDate: (m: Movie) => string = pathOr("", ['showings', 0, 'date']);
export const selectMovieAvgScore = (m: Movie) => {
    return mean(m?.scores.map(selectValue)) || 0
}

function MovieTable({ movies }: { movies: Movie[] }) {
    const columns = [
    
        // {
            // header: "Avg Score",
            // accessorKey: 'avg_score',
            // accessorFn: (movie: Movie) => {
            //     const avgScore = mean(movie.scores.map(selectValue));
            //     if (isNaN(avgScore)) {
            //         return "--"
            //     } else {
            //         return avgScore.toFixed(2)
            //     }
            // }
        // },
        // {
        //     header: "Showings",
        //     accessorKey: 'showings',
        //     accessorFn: (movie: Movie) => {
        //         return <div key={`${movie.id}-showings`}>
        //             {movie.showings.map(s => (
        //                 <Link key={`showing-${s.id}`} to={`/showings/${s.id}`}>{dayjs(s.date).format("MM/DD/YY")}</Link>
        //             ))}
        //         </div>
        //     }
        // }
        {
            field: 'title',
            headerName: 'Title',
            width: 500,
        },
        {
            field: 'submitter',
            headerName: 'Submitter',
            width: 150,
            valueGetter: (_: unknown, row:Movie) => `${row?.scientist?.name || ''}`,
        },
        {
            field: 'avg_score',
            headerName: "Avg Score",
            width: 150,
            valueGetter: (_: unknown, movie: Movie) => {
                console.log("movie.scores", movie.scores)
                const avgScore = mean(movie.scores.map(selectValue));
                if (isNaN(avgScore)) {
                    return "--"
                } else {
                    return avgScore.toFixed(2)
                }
            }
        },
        // {
        //     field: 'showings',
        //     headerName: 'Showings',
        //     valueGetter: (movie: Movie) => {
        //         return <div key={`${movie.id}-showings`}>
        //             {movie.showings.map(s => (
        //                 <Link key={`showing-${s.id}`} to={`/showings/${s.id}`}>{}</Link>
        //             ))}
        //         </div>
        //     }
        // }
    ]
    return (
        <DataGrid
                rows={movies}
                columns={columns}
            />
    )
}

// function MovieTable({ movies, selectSort = selectMovieDate }: { movies: Movie[], selectSort?: (movie: Movie) => any }) {
//     const [sorting, setSorting] = createSignal<SortingState>([])

//     //✅ GOOD: This will not cause an infinite loop of re-renders because `columns` is a stable reference
//     const columns = useMemo(() => [
        // {
        //     header: "Title",
        //     accessorKey: 'title',
        // },
        // {
        //     header: "Submitter",
        //     accessorKey: 'submitter',
        //     accessorFn: (movie: Movie) => {
        //         return movie?.scientist?.name || "-";
        //     }
        // },
        // {
        //     header: "Avg Score",
        //     accessorKey: 'avg_score',
        //     accessorFn: (movie: Movie) => {
        //         const avgScore = mean(movie.scores.map(selectValue));
        //         if (isNaN(avgScore)) {
        //             return "--"
        //         } else {
        //             return avgScore.toFixed(2)
        //         }
        //     }
        // },
        // {
        //     header: "Showings",
        //     accessorKey: 'showings',
        //     accessorFn: (movie: Movie) => {
        //         return <div key={`${movie.id}-showings`}>
        //             {movie.showings.map(s => (
        //                 <Link key={`showing-${s.id}`} to={`/showings/${s.id}`}>{dayjs(s.date).format("MM/DD/YY")}</Link>
        //             ))}
        //         </div>
        //     }
        // }
//     ], []);

//     //✅ GOOD: This will not cause an infinite loop of re-renders because `data` is a stable reference
//     const [data, setData] = useState<Movie[]>(() => movies);

//     // Columns and data are defined in a stable reference, will not cause infinite loop!

//     const table = useReactTable({
//         columns,
//         data: data ?? fallbackData,
//         getCoreRowModel: getCoreRowModel()
//     })



//     return (
//         <StyledTable>
//             <table>
//                 <thead>
//                     {
//                         table.getHeaderGroups().map(headerGroup => (
//                             <tr key={headerGroup.id}>
//                                 {headerGroup.headers.map(header => (
//                                    <th colSpan={header.colSpan}>
//                                      <div
//                                        className={
//                                          header.column.getCanSort()
//                                            ? 'cursor-pointer select-none'
//                                            : undefined
//                                        }
//                                        onClick={header.column.getToggleSortingHandler()}
//                                      >
//                                        {flexRender(
//                                          header.column.columnDef.header,
//                                          header.getContext()
//                                        )}
//                                        {{
//                                          asc: ' 🔼',
//                                          desc: ' 🔽',
//                                        }[header.column.getIsSorted() as string] ?? null}
//                                      </div>
//                                  </th>
//                                 ))}
//                             </tr>
//                         ))
//                     }
//                 </thead>
//                 <tbody>
//                     {
//                         table.getRowModel().rows.map(row => {
//                             return (
//                                 <tr key={row.id}>
//                                     {
//                                         row.getVisibleCells().map(cell => {
//                                             return (
//                                                 <td id={cell.id}>
//                                                     {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                                                 </td>
//                                             )
//                                         })
//                                     }
//                                 </tr>
//                             )
//                         })
//                     }
//                 </tbody>
//             </table>
//         </StyledTable>
//     )
// }

// function MovieRow({ movie, number }: { movie: Movie, number: number }) {
//     const scientist = movie.scientist;
//     const scores = movie.scores || [];
//     const showings = movie.showings || [];
//     const avgScore = mean(scores.map(selectValue));
//     return (
//         <tr>
//             <td style={{ textAlign: 'center' }}>{number}</td>
//             <td>
//                 <MovieLink movie={movie} ></MovieLink>
//             </td>
//             <td>{scientist?.name || "---"}</td>
//             <td>{!isNaN(avgScore) ? avgScore.toFixed(2) : '---'}</td>
//             <td>{!showings.length ? "---" : showings.map(s => (
//                 <>
//                     <Link key={`showing-${s.id}`} to={`/showings/${s.id}`}>{dayjs(s.date).format("MM/DD/YY")}</Link>{' '}
//                 </>
//             )) || "Unknown"}</td>
//         </tr>
//     )
// }

export default MovieTable